<template>
  <div>
    <a-modal
      v-model="visibleModalManageLevel"
      class="mod"
      :title="status.isEdit ? 'Form Edit Level' : 'Form Create Level'"
      @cancel="toggleModalManageLevel"
      centered
    >
      <form-level :dataLevel="newLevel" @handle-change="handleChange" />
      <template slot="footer">
        <a-button size="large" key="back" @click="toggleModalManageLevel">
          Cancel
        </a-button>
        <a-button
          size="large"
          key="submit"
          type="primary"
          :loading="loadingActionModalManageLevel"
          @click="handleOkModalManageLevel"
        >
          {{ status.isEdit ? "Edit" : "Create" }} Now
        </a-button>
      </template>
    </a-modal>
    <div class="d-flex align-items center">
      <div class="ml-auto">
        <a-button
          @click.prevent="toggleModalManageLevel('add')"
          size="large"
          type="primary"
          ><a-icon type="plus" />Create Level</a-button
        >
      </div>
    </div>
    <div class="mt-4">
      <a-table
        :columns="levelColumns"
        :data-source="levelDataTable"
        :pagination="levelPagination"
        :loading="levelLoadingTable"
        bordered
      >
        <div slot="action" slot-scope="value, record">
          <a-button
            @click.prevent="toggleModalManageLevel('edit', record)"
            class="mr-3 text-warning border border-warning"
            size="large"
          >
            <a-icon type="edit" />Edit
          </a-button>
          <a-button
            @click.prevent="handleDeleteLevel(record)"
            class="text-danger border border-danger"
            size="large"
          >
            <a-icon type="delete" />Delete
          </a-button>
        </div>
      </a-table>
    </div>
  </div>
</template>

<script>
const formLevel = () => import('@/components/app/Admin/Master/Form/Level')

const levelColumns = [
  {
    title: 'Name',
    dataIndex: 'nama',
    key: 'nama',
    scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Order',
    dataIndex: 'urutan',
    key: 'urutan',
    align: 'center',
    // scopedSlots: { customRender: 'nama' },
  },
  {
    title: 'Action',
    dataIndex: 'action',
    key: 'action',
    scopedSlots: { customRender: 'action' },
    align: 'center',
  },
]
export default {
  name: 'Level',
  components: {
    formLevel,
  },
  data() {
    return {
      levelColumns,
      visibleModalManageLevel: false,
      loadingActionModalManageLevel: false,
      loadingDeleteLevel: false,
      levelLoadingTable: false,
      levelDataTable: [],
      levelPagination: {},
      status: {
        isEdit: false,
        isCreate: false,
      },
      newLevel: {
        nama: null,
        urutan: null,
      },
      idEdit: null,
    }
  },
  methods: {
    handleChange(payload) {
      const { value, column } = payload
      console.log('parent', payload)
      this.newLevel[column] = value
    },
    toggleModalManageLevel(status, data) {
      this.visibleModalManageLevel = !this.visibleModalManageLevel
      if (!this.visibleModalManageLevel) {
        setTimeout(() => {
          this.status = {
            isEdit: false,
            isCreate: false,
          }
          this.newLevel = {
            nama: null,
            urutan: null,
          }
          this.idEdit = null
        }, 500)
        return ''
      }

      if (status) {
        this.status[status === 'add' ? 'isCreate' : 'isEdit'] = true
        if (status === 'edit') {
          this.idEdit = data.key
          this.newLevel = {
            nama: data.nama,
            urutan: data.urutan,
          }
        }
      }
    },
    handleOkModalManageLevel() {
      const content = `${this.status.isEdit ? 'Are you sure want to edit this level?' : 'Are you sure want to create new level'}`
      if (this.isValidateForm) {
        this.$notification.error({
          message: 'Warning',
          description: 'All fields are required',
        })
      } else {
        for (let i = 0; i < this.levelDataTable.length; i++) {
          const level = this.levelDataTable[i]
          if (level.urutan === this.newLevel.urutan) {
            return this.$notification.error({
              message: 'LEVEL HAS SAME ORDER',
              description: 'other level already has this order. Please choose another order.',
            })
          }
        }
        this.$confirm({
          title: 'Warning',
          content: (
            <div>{content}</div>
          ),
          onOk: () => {
            this.loadingActionModalManageLevel = true
            const payload = this.status.isEdit ? {
              id: this.idEdit,
              dataLevel: this.newLevel,
            } : {
              dataLevel: this.newLevel,
            }
            this.$store.dispatch(`master/${this.status.isEdit ? 'EDIT' : 'POST'}_LEVEL`, payload)
              .then(isSuccess => {
                this.loadingActionModalManageLevel = false
                this.toggleModalManageLevel()
                this.fetchDataLevel()
                if (isSuccess) {
                  const description = this.status.isEdit ? 'Level has been updated' : 'Level has been created'
                  this.$notification.success({
                    message: 'Success',
                    description,
                  })
                } else {
                  const description = this.status.isEdit ? 'Level has not been updated' : 'Level has not been created'
                  this.$notification.error({
                    message: 'Failed',
                    description,
                  })
                }
              })
          },
          onCancel: () => {
          },
          centered: true,
          icon: 'warning',
          okType: 'primary',
          okText: this.status.isEdit ? 'Edit Now' : 'Create Now',
          cancelText: 'Cancel',
        })
      }
    },
    handleDeleteLevel(data) {
      this.$confirm({
        title: 'Warning',
        content: (
          <div>Are you sure want to delete this level?</div>
        ),
        onOk: () => {
          this.levelLoadingTable = true
          this.loadingDeleteLevel = true
          this.$store.dispatch('master/DELETE_LEVEL', {
            id: data.key,
          })
            .then(isSuccess => {
              this.fetchDataLevel()
              this.loadingDeleteLevel = false
              if (isSuccess) {
                this.$notification.success({
                  message: 'Success',
                  description:
                    'Level has been deleted',
                })
              } else {
                this.$notification.error({
                  message: 'Failed',
                  description: 'Level has not been deleted',
                })
              }
            })
        },
        onCancel: () => {
          this.loadingDeleteLevel = false
        },
        centered: true,
        icon: 'warning',
        okType: 'danger',
        okText: 'Delete',
        cancelText: 'Cancel',
      })
    },
    fetchDataLevel(params = { page: 1, order: 'ASC', search: '', type: '', sortBy: '' }) {
      this.levelLoadingTable = true
      this.$store.dispatch('master/FETCH_LEVEL', { page: params.page, order: params.order, search: params.search, sortBy: params.sortBy })
        .then(res => {
          this.levelLoadingTable = false
          // const pagination = { ...this.pagination }
          // pagination.total = res.total
          // this.pagination = pagination
          // console.log(res)
          this.levelDataTable = res.map(el => {
            return {
              ...el,
              key: el.id,
            }
          })
        })
    },
  },
  mounted() {
    this.fetchDataLevel()
  },
  computed: {
    isValidateForm() {
      if (this.newLevel.nama && this.newLevel.urutan) {
        return false
      }
      return true
    },
  },
}
</script>

<style>
</style>
